//@ts-ignore
import {onUnmounted} from "vue";
import {router} from "@inertiajs/vue3";
import {useToast} from 'vue-toastification';

const toast = useToast();

export default class Toast {
    public static dismiss(toast_id){
        toast.dismiss(toast_id);
    }
    public static success(message: string, options = {}) {
        this.createToast('success', message, options);
    }

    public static error(message: string, options = {}) {
        this.createToast('error', message, options);
    }

    public static info(message: string, options = {}) {
        this.createToast('info', message, options);
    }
    public static warning(message: string, options = {}) {this.createToast('warning', message, options);}

    public static async createToast(type: string, message: string, options) {
        if (typeof window === 'undefined') {
            return;
        }

        if (type in toast) {
            toast[type](message, options);
        } else {
            console.error(`Unknown toast type: ${type}`);
        }
    }

    public static async clearAll() {
        toast.clear();
    }
}

export function useToastAutomaticHandler(){
    onUnmounted(async () => {
       await Toast.clearAll();
    });

    router.on('finish',  () => {
         Toast.clearAll();
    });
}
