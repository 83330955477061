import {usePage} from '@inertiajs/vue3'
import {UserType} from "~/typescript/User/UserType";
import {NotificationType} from "~/helper/Navigator/DsNotification";

const page = usePage()

export class DsUser {

    public static isAuth(): boolean {
        // @ts-ignore
        return page.props?.user?.is_auth ?? false;
    }

    // @ts-ignore
    public static getUser(key = undefined):  UserType | any   {
        // @ts-ignore
        const user = page.props?.user?.data;
        if(!key || !user){
            return user;
        }
        return user[key];
    }

    public static getUserNotification() : NotificationType[] {
        // @ts-ignore
        return page.props.user.notifications;
    }
    public static haveRole(...roles: string[]): boolean {
        const user = this.getUser()

        return user.roles.some((role: { slug: string; }) => roles.includes(role.slug));
    }

    public static haveOneRole(...roles: string[]): boolean {
        const user = this.getUser()
        return user.roles.some((role: { slug: string; }) => roles.includes(role.slug));
    }

    public static isImpersonating(): boolean {
        // @ts-ignore
       return page.props.user.is_impersonating
    }
}
