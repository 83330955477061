import {ref} from "vue";
import {defineStore} from "pinia";

export const useModalLocationHistoryStore = defineStore('modal_location_history', () => {
        const modal_location_product_open = ref(false);
        const modal_location_product_type = ref('locker');
        const modal_location_product_slug = ref('');

        function openHistoryModal(slug: string, type: 'locker' | 'epal' | 'product') {
            modal_location_product_slug.value = slug;
            modal_location_product_type.value = type;
            modal_location_product_open.value = true;
        }

        return {
            modal_location_product_slug,
            modal_location_product_type,
            modal_location_product_open,
            openHistoryModal
        }
    }
);
