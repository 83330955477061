import {computed, ref} from 'vue';
import {router} from "@inertiajs/vue3";
import {DsRoute} from "~/helper/RouteManager.ts";
import {numberHourToString} from "~/helper/format/TimeAndDateFormat";

export const checked_calendar_list = computed(() => {
    let checked_calendar_list = [];
    days.value.forEach((day) => {
        if (!day.active) {
            return;
        }
        if (day.checked) {
            checked_calendar_list.push(day)
        }
    });
    return checked_calendar_list;
});

export const select_day_present_time_sum = computed(() => {
    let sum = 0;
    checked_calendar_list.value.forEach((day) => {
        sum += day?.today_time ?? 0;
    });
    return sum;
});

export const days = ref([]);

export const planned_time = ref(0);

export const contract_list = ref(null);

export const present_time = ref(0);

export const diff_hour = computed(() => {
    return present_time.value - planned_time.value;
});

export const user_selected = ref(null);

export function toggleCheckCell(day) {
    if (!day.active) {
        return;
    }
    day.checked = !day.checked;
}

export const auto_action_list = ['sick_leave','school','leave','recovery','no_pay'];

export const action_list = {
    timecheck: {
        name: 'Pointage',
        slug: 'timecheck',
        icon_class: 'fas fa-clock',
        color_bg: 'bg-emerald-500 hover:bg-emerald-400',
        color_text: 'text-emerald-500',
    },
    leave: {
        name: 'Congé',
        slug: 'leave',
        icon_class: 'fas fa-bed',
        color_bg: 'bg-primary-500 hover:bg-primary-400',
        color_text: 'text-primary-500',
    },
    sick_leave: {
        name: 'Maladie',
        slug: 'sick_leave',
        icon_class: 'fas fa-briefcase-medical',
        color_bg: 'bg-purple-500 hover:bg-purple-400',
        color_text: 'text-purple-500',
    },
    school: {
        name: 'École',
        slug: 'school',
        icon_class: 'fas fa-school',
        color_bg: 'bg-amber-500 hover:bg-amber-400',
        color_text: 'text-amber-500',
    },
    remote: {
        name: 'Télétravail',
        slug: 'remote',
        icon_class: 'fas fa-laptop-house',
        color_bg: 'bg-orange-500 hover:bg-orange-400',
        color_text: 'text-orange-500',
    },
    recovery: {
        name: 'Récup',
        slug: 'recovery',
        icon_class: 'fa-solid fa-plane-departure',
        color_bg: 'bg-gray-500 hover:bg-gray-400',
        color_text: 'text-gray-500',
    },
    no_pay: {
        name: 'Sans solde',
        slug: 'no_pay',
        icon_class: 'fa-solid fa-hand-middle-finger',
        color_bg: 'bg-slate-950 hover:bg-slate-800',
        color_text: 'text-slate-700',
    },
};


export const modal = ref({
    type: action_list[0],
    open: false,
    create: true,
    start: null,
    end: null,
    timecheck_id: null,
})

export function openModal(type = null, create = true, start = null, end = null, id = null, day = null,) {
    if (type === null) {
        type = action_list['timecheck'];
    } else {
        type = action_list[type];
    }

    if (day !== null) {
        days.value.forEach((day_item) => {
            if (day_item.checked) {
                toggleCheckCell(day_item);
            }
        });
        toggleCheckCell(day);
    }

    start = start ? numberHourToString(start, true) : '';
    end = end ? numberHourToString(end, true) : '';

    modal.value.type = type;
    modal.value.create = create;
    modal.value.start = start;
    modal.value.end = end;
    modal.value.open = true;
    modal.value.timecheck_id = id;
}

export function closeModal() {
    modal.value.open = false;
}

export function deleteTimecheck(id) {
    router.delete(DsRoute('timeclock.manage.delete', {id: id}), {
        preserveState: false,
        preserveScroll: true,
        onBefore: () => {
            closeModal();
        },
    });
}
