import route from '../../../vendor/tightenco/ziggy';

export class RouteManager {
    static makeUrlFromRouteName(route_name: string, params = undefined, absolute = undefined): string | undefined {
        return route(route_name, params, absolute);
    }

    static doesRouteExist(name: string) {
        // @ts-ignore
        return route(undefined, undefined, undefined, Ziggy).check(name);
    }

    static isRoute(route_name: string | Array<string>, params = undefined) {
        // @ts-ignore
        if (Array.isArray(route_name)) {
            for (let i = 0; i < route_name.length; i++) {
                // @ts-ignore
                if (route(undefined, undefined, undefined, Ziggy).current(route_name[i], params)) {
                    return true;
                }
            }
        }
        // @ts-ignore
        return route(undefined, undefined, undefined, Ziggy).current(route_name, params);
    }

    static getCurrentRouteName() {
        // @ts-ignore
        return route(undefined, undefined, undefined, Ziggy).current();
    }
}

export function DsRoute(route_name: string, params = undefined, absolute = undefined): string | undefined {
    return RouteManager.makeUrlFromRouteName(route_name, params, absolute);
}
