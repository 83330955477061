export function formatDateToString(inputDate: string | number | Date, show_year = true,show_hours = false) {
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    if(show_hours){
        return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}${show_year ? '/' + year : ''} ${formatDateToHourString(date)}`;
    }
    return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}${show_year ? '/' + year : ''}`;
}

export function formatDateToHourString(inputDate: string | number | Date, show_seconds = false) {
    const date = new Date(inputDate);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const time = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    if(show_seconds){
        return `${time}:${seconds.toString().padStart(2, '0')}`;
    }
    return time;
}
export function numberHourToString(integerHour: number, dot_notation = false) {
    let negative = false;

    if (integerHour < 0) {
        integerHour = -integerHour;
        negative = true;
    }

    let hours = Math.floor(integerHour);
    let minutes = Math.round((integerHour - hours) * 60);
    if(minutes === 60){
        hours++;
        minutes = 0;
    }

    if (dot_notation) {
        return `${negative ? '-' : ''}${hours <= 9 ? 0 + hours.toString() : hours}:${minutes <= 9 ? 0 + minutes.toString() : minutes}`;
    }

    if (hours === 0 && minutes === 0) {
        return `${negative ? '-' : ''}${hours}h`;
    }


    let hours_string = `${hours !== 0 ? hours + 'h' : ''}`;
    let minutes_string = `${minutes !== 0 ? minutes + 'm' : ''}`;

    if (minutes <= 9 && minutes !== 0 && hours !== 0) {
        minutes_string = `0${minutes_string}`;
    }


    return `${negative ? '-' : ''} ${hours_string} ${minutes_string}`;

}

export function formatDateForDsInput(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}
