import {DsUser} from "./helper/User/DsUser.ts";
import {ref} from "vue";
import {DsAlertConfirm} from "./composable/modal-confirm.ts";

function isRunningStandalone() {
	return (window.matchMedia('(display-mode: standalone)').matches);
}
const current_date = ref(new Date().toISOString().split('T')[0]);
const timeout = ref(null);

let is_checking = false;
async function checkForLogout() {
	if(is_checking){
		return;
	}
	is_checking = true;

	if (!isRunningStandalone() || !DsUser.isAuth() || localStorage.getItem('mobileNoLogout') === 'true'){
		is_checking = false;
		return;
	}

	const today = new Date().toISOString().split('T')[0];


	if (current_date.value && current_date.value !== today) {
		//Pour forcer la déconnexion si aucun choix n'est fait
		timeout.value = setTimeout(() => {
			window.location.href = '/logout';
		},1000 * 60);

		const extend_session = await DsAlertConfirm('Déconnexion',
			'<div>Vous allez être déconnecté automatiquement</div><div class="mt-4 text-sm font-bold">Voulez-vous prolonger la session ?</div>',
			'Déconnexion', 'Prolonger la session');

		if(extend_session){
			clearTimeout(timeout.value);
			is_checking = false;
			current_date.value = today;
			return;
		}

		 window.location.href = '/logout';
	}
	is_checking = false;
}

checkForLogout();

setInterval(checkForLogout, 1000 * 60 * 10);


document.addEventListener('visibilitychange', function () {
	checkForLogout();
});
window.addEventListener('pageshow', function (event) {
	checkForLogout();
});

window.addEventListener('focus', function () {
	checkForLogout();
});

window.addEventListener('blur', function () {
	checkForLogout();
});
