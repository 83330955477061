import {ref} from "vue";

const defaultModal = {
    'title': 'Demande de confirmation',
    'content': 'Êtes-vous sûr de vouloir effectuer cette action ?',
    'leftButton': 'Annuler',
    'rightButton': 'Valider',
    'colorLeftButton': 'bg-red-600 hover:bg-red-500 focus:bg-red-500',
    'colorRightButton': 'bg-green-600 hover:bg-green-500 focus:bg-green-500',
}

export const modalConfirmTitle = ref<string>();
export const modalConfirmContent = ref<string>();
export const modalConfirmLeftButton = ref<string>();
export const modalConfirmRightButton = ref<string>();
export const modalConfirmShow = ref(false);
export const DsModalConfirmResponse = ref<boolean | null>(null);
export const modalConfirmColorLeftButton = ref<string>();
export const modalConfirmColorRightButton = ref<string>();

let modalResolve: ((value: boolean) => void) | null = null;
const promise = ref()

export async function DsAlertConfirm(newTitle?: string, newContent?: string, newLeftButton?: string, newRightButton?: string,
                                     colorLeftButton?: string, colorRightButton?: string) {
    modalConfirmTitle.value = newTitle ?? defaultModal.title
    modalConfirmContent.value = newContent ?? defaultModal.content
    modalConfirmLeftButton.value = newLeftButton ?? defaultModal.leftButton
    modalConfirmRightButton.value = newRightButton ?? defaultModal.rightButton
    modalConfirmColorLeftButton.value = colorLeftButton ?? defaultModal.colorLeftButton
    modalConfirmColorRightButton.value = colorRightButton ?? defaultModal.colorRightButton

    modalConfirmShow.value = true;

    return new Promise<boolean>((resolve) => {
        modalResolve = resolve;
    });
}

export function modalConfirmValidate(response: boolean) {
    if (modalResolve) {
        modalResolve(response);
    }
    DsModalConfirmResponse.value = response
    modalConfirmShow.value = false
}

export function modalConfirmResetState() {
    modalConfirmTitle.value = defaultModal.title
    modalConfirmContent.value = defaultModal.content
    modalConfirmLeftButton.value = defaultModal.leftButton
    modalConfirmRightButton.value = defaultModal.rightButton
    modalResolve = null;
}
